<template>
    <!-- External Filter -->
    <div v-if="!efect" class="block md:flex w-full">
      <div style="width: 100%; height: 100%;">
            <div class="custom-container">
                <div class="custom-header">
                    <div class="grid md:flex mx-1 flex">
                        <button id="agButtonConfirm" name="agButtonConfirm" class="border-2 border-mustard bg-white text-mustard focus:bg-blue-700 px-8 py-1 mx-auto rounded-md cursor-pointer" v-on:click="externalFilterChanged(index)">Enviar a todos</button>
                    </div>
                    
                </div>
            </div>
      </div>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    export default {
        props: {
            fields: {
                type: Array,
                default: () => [] 
            }
        },
        setup(props, {emit}){
            const store = useStore()
            const fields = ref([]);
            const count = ref(0);

            onMounted(async() => {
               await props.fields.forEach(element => {
            
                const values = element.values.filter(x => x !== undefined)
            
                fields.value.push({
                    "type": element.type,
                    "name": element.name,
                    "label": element.label,
                    "value": "",
                    "values": values
                });
               });
            })

            const externalFilterChanged = () => {
                count.value++
                console.log("emit tablebutton!:", count.value);
                store.dispatch('setForwardingCertificatesStatusAction', count.value);
            }

            return {
                externalFilterChanged,
                fields
            }
        }
    }
    
</script>

<style scoped>
.custom-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

</style>
